/** Text **/
.textSmall {
    font-size: .75rem;
    color: #FFFFFFDE;
    font-family: SuisseIntl-Regular;
}

.textMedium {
    font-size: 1rem;
    color: #FFFFFFDE;
    font-family: SuisseIntl-Regular;
}

.textLarge {
    font-size: 1.25rem;
    color: #FFFFFFDE;
    font-family: SuisseIntl-Regular;
}

.textSmallPositive {
    font-size: .75rem;
    color: #5BD0B3;
    font-family: SuisseIntl-Regular;
}

.textMediumPositive {
    font-size: 1rem;
    color: #5BD0B3;
    font-family: SuisseIntl-Regular;
}

.textLargePositive {
    font-size: 1.25rem;
    color: #5BD0B3;
    font-family: SuisseIntl-Regular;
}

.textSmallNegative {
    font-size: .75rem;
    color: #E8AD77;
    font-family: SuisseIntl-Regular;
}

.textMediumNegative {
    font-size: 1rem;
    color: #E8AD77;
    font-family: SuisseIntl-Regular;
}

.textLargeNegative {
    font-size: 1.25rem;
    color: #E8AD77;
    font-family: SuisseIntl-Regular;
}

/** Label **/
.labelSmall {
    font-size: .75rem;
    color: #FFFFFF99;
    font-family: SuisseIntl-Regular;
}

.labelMedium {
    font-size: 1rem;
    color: #FFFFFF99;
    font-family: SuisseIntl-Regular;
}

.labelLarge {
    font-size: 1.25rem;
    color: #FFFFFF99;
    font-family: SuisseIntl-Regular;
}

/** Color Text **/
.textPrimarySmall {
    font-size: .75rem;
    color: #5BD0B3;
    font-family: SuisseIntl-Regular;

}

.textPrimaryMedium {
    font-size: 1rem;
    color: #5BD0B3;
    font-family: SuisseIntl-Regular;
}

.textPrimaryLarge {
    font-size: 1.25rem;
    color: #5BD0B3;
    font-family: SuisseIntl-Regular;
}

/** Timer Text **/
.textTimerSmall {
    font-size: .75rem;
    color: #5BD0B3;
}

.textTimerMedium {
    font-size: 1rem;
    color: #5BD0B3
}

.textTimerLarge {
    font-size: 1.25rem;
    color: #5BD0B3
}