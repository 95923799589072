.buttonPrimary {
    font-size: 1rem;
    color: #121212;
    background-color: #5BD0B3;
    margin: 0rem;
    padding: 1rem;
    display: grid;
    justify-items: center;
    align-items: center;
    border-radius: .25rem;
}
 
.buttonSecondary {
    font-size: 1rem;
    width: 8rem;
    color: #121212;
    background-color: #5BD0B3;
    margin: auto;
    padding: .5rem;
    display: grid;
    justify-items: center;
    align-items: center;
    border-radius: 1rem;
}

.buttonCircle {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    color: #121212;
    background-color: #5BD0B3;
    margin: auto;
    padding: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    border-radius: 50%;
}

.buttonPrimaryRounded {
    font-size: 1rem;
    color: #121212;
    background-color: #5BD0B3;
    margin: 0rem;
    padding: 1rem;
    display: grid;
    justify-items: center;
    align-items: center;
    border-radius: 1.75rem;
}