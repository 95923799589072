html {}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: fill-available;
  margin: 0;
  background-color: #121212;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  color: "white";
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {}

@font-face {
  font-family: "SuisseIntl-Regular";
  src: local("SuisseIntl-Regular"),
    url("./fonts/SuisseIntl-Regular.ttf") format("truetype");
  font-weight: normal;
}